import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import { Box, Button, Container, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { WithOptimizelyProps, withOptimizely } from "@optimizely/react-sdk";
import { useRouter } from "next/compat/router";
import Image from "next/legacy/image";
import NextLink from "next/link";
import { useTranslation } from "next-i18next";
import React, { ReactElement } from "react";
import {
  useCurrentRefinements,
  useHierarchicalMenu,
  useInstantSearch,
} from "react-instantsearch";

import useMyCountryQuery from "../../util/useMyCountryQuery";
import About from "../About";
import { useContent } from "../ContentProvider";
import CountrySelect from "../CountrySelect";
import ItemSelect from "../ItemSelect";
import LanguageSwitcher from "../LanguageSwitcher";
import LinkText from "../LinkText";
import NavBar from "../NavBar";
import SideBar from "../SideBar";

type Props = WithOptimizelyProps & {
  showAbout?: boolean;
};

const Search = withOptimizely(({ showAbout }: Props): ReactElement => {
  const { t } = useTranslation(["common", "topics"]);
  const theme = useTheme();
  const { countries, topics, host } = useContent();
  const { items } = useCurrentRefinements({
    includedAttributes: ["topics", "locations.lvl0"],
  });
  const { results } = useInstantSearch();
  const { refine } = useHierarchicalMenu({
    attributes: ["locations.lvl0", "locations.lvl1"],
    limit: 1000,
  });
  const suicidalThoughtsCount =
    results?.disjunctiveFacets?.find(({ name }) => name === "topics")?.data[
      "Suicidal thoughts"
    ] ?? 0;
  const selectedAlgoliaTopics =
    items.find(({ attribute }) => attribute === "topics")?.refinements ?? [];
  const selectedTopics = selectedAlgoliaTopics.map((algoliaTopic) =>
    topics.find(
      (topic) => topic.name.toLowerCase() === algoliaTopic.label.toLowerCase(),
    ),
  );
  const selectedCountryName = items
    .find(({ attribute }) => attribute === "locations.lvl0")
    ?.refinements[0]?.label.split(" > ")[0];
  const selectedSubdivisionName = items
    .find(({ attribute }) => attribute === "locations.lvl0")
    ?.refinements[0]?.label.split(" > ")[1];
  const selectedCountry =
    selectedCountryName != null ?
      countries.find(({ name }) => name === selectedCountryName)
    : undefined;
  const selectedSubdivision =
    selectedSubdivisionName != null ?
      selectedCountry?.subdivisions?.find(
        ({ name }) => name === selectedSubdivisionName,
      )
    : undefined;
  const { data } = useMyCountryQuery({
    ssr: false,
    variables: { languageCode: useRouter()?.locale },
  });

  const setSelectedCountry = (country) => {
    refine(country.name);
  };

  return (
    <>
      <NavBar variant={(!selectedCountry && "minimal") || null}>
        <LanguageSwitcher variant="standard" />
        <SideBar />
      </NavBar>
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          py: 5,
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gap: 2,
          }}
        >
          {!selectedCountry && (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  justifySelf: "center",
                  maxWidth: host?.logoMaxWidth || 250,
                }}
              >
                {host?.logo ?
                  <Image
                    src={host.logo.url}
                    alt={host.name}
                    layout="intrinsic"
                    width={host.logo.width}
                    height={host.logo.height}
                    priority
                  />
                : <Image
                    layout="intrinsic"
                    src="/findahelpline-logo.svg"
                    alt={t("Find A Helpline logo")}
                    width={250}
                    height={42}
                    priority
                  />
                }
              </Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "secondary.dark",
                }}
                component="h1"
              >
                {selectedTopics[0] ?
                  t(
                    "Struggling with {{topic}}? Get free, confidential support from a helpline or hotline near you. Online chat, text or phone.",
                    { topic: t(selectedTopics[0].name, { ns: "topics" }) },
                  )
                : t(
                    "Free, confidential support from a helpline or hotline near you. Online chat, text or phone.",
                  )
                }
              </Typography>
            </>
          )}
          <CountrySelect userCountryName={data?.myCountry?.name} />
          {!selectedCountry && (
            <>
              {host == null && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textDecoration: "none",
                      color: "text.secondary",
                    }}
                    component="h2"
                  >
                    {t("Quick links")}
                  </Typography>
                  {data?.myCountry && (
                    <Link
                      component="button"
                      onClick={(event) => {
                        event.preventDefault();
                        setSelectedCountry(data.myCountry);
                      }}
                      sx={{
                        color: "secondary.dark",
                        textDecoration: "underline",
                        fontFamily: (theme) => theme.typography.fontFamily,
                        fontSize: "16px",
                        lineHeight: "36px",
                      }}
                    >
                      {t("Helplines in {{countryName}}", {
                        countryName: data.myCountry.localizedName,
                      })}
                    </Link>
                  )}
                  <LinkText
                    href="/faq"
                    sx={{
                      color: "secondary.dark",
                      fontSize: "16px",
                      lineHeight: "36px",
                    }}
                    underline
                  >
                    {t("Using a helpline: What to expect")}
                  </LinkText>
                </Box>
              )}
              {host?.pageFaqs && (
                <Box
                  sx={{
                    color: "secondary.dark",
                    textDecoration: "underline",
                    fontSize: "16px",
                    lineHeight: "36px",
                  }}
                >
                  <LinkText
                    href="/faq"
                    sx={{
                      color: "secondary.dark",
                      fontSize: "16px",
                      lineHeight: "36px",
                    }}
                    underline
                  >
                    {t("What can I expect when contacting a helpline?")}
                  </LinkText>
                </Box>
              )}
            </>
          )}
          <Box
            sx={{
              backgroundColor: "background.paper",
              p: 2,
              my: 0,
              mx: -2,
              [theme.breakpoints.up("md")]: {
                borderRadius: "10px",
                margin: 0,
              },
              display: selectedCountry == null && "none",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: (theme) => theme.typography.fontFamily,
                  }}
                  gutterBottom
                >
                  {t("What would you like help with?")}
                </Typography>
                {suicidalThoughtsCount > 0 && (
                  <>
                    <Typography variant="body2" color="secondary">
                      {t("Quick link")}
                    </Typography>
                    <Box pt={1} pb={3}>
                      <NextLink
                        legacyBehavior
                        href={{
                          pathname: `/countries/${selectedCountry?.code?.toLowerCase()}${
                            selectedSubdivision ?
                              `/${selectedSubdivision.code.toLowerCase()}`
                            : ""
                          }/topics/suicidal-thoughts`,
                        }}
                        passHref
                        prefetch={process.env.NODE_ENV === "production"}
                      >
                        <Button
                          color="secondary"
                          variant="contained"
                          size="large"
                          endIcon={<ArrowRightAltRoundedIcon />}
                        >
                          {t("Suicidal Thoughts")}
                        </Button>
                      </NextLink>
                    </Box>
                    <Typography variant="body2" color="secondary">
                      {t("Or select topic or topics (optional)")}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <ItemSelect attribute="topics" max={10} center />
              </Grid>
              <Grid item xs={12}>
                <NextLink
                  legacyBehavior
                  href={{
                    pathname: `/countries/${selectedCountry?.code?.toLowerCase()}${
                      selectedSubdivision ?
                        `/${selectedSubdivision.code.toLowerCase()}`
                      : ""
                    }${selectedTopics.length === 1 ? `/topics/${selectedTopics[0].slug}` : ""}`,
                    query:
                      selectedTopics.length > 1 ?
                        { topics: selectedTopics.map(({ name }) => name) }
                      : undefined,
                  }}
                  passHref
                  prefetch={process.env.NODE_ENV === "production"}
                >
                  <Button
                    data-testid="searchButton"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    {t("Search for helplines")}
                  </Button>
                </NextLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      {!selectedCountry && showAbout && (host == null || host.pageAbout) && (
        <About />
      )}
    </>
  );
});

export default Search;
